const events = ['click', 'rightclick', 'dblclick', 'drag', 'dragstart', 'dragend', 'mouseup', 'mousedown', 'mouseover', 'mouseout'];
import { mapGetters } from 'vuex';

export default {
  name: 'marker',
  template: '<div></div>',
  created() {
    this.$events = events;
  },
  computed: {
    ...mapGetters({
      isOpenInfoWindow: 'gis/getIsOpenInfoWindow',
    }),
  },
  props: {
    mapObject: {},
    geometry: {
      type: Object,
    },
    properties: {
      type: Object,
    },
    dataObj: {
      type: Object,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    label: {},
    visible: {
      default: true,
    },
    alwaysActivate: {
      default: false,
    },
    markerInfoWindow: {
      default: true,
    },
    status: {
      type: Number,
      default: 1,
    },
    isEventMarker: {
      type: Boolean,
      default: false,
    },
    iwContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gisMarkerData: null,
      gisMarkerIconNormal: null,
      gisMarkerIconBreak: null,
      gisMarkerIconRepair: null,
      gisMarkerIconSelected: null,
    };
  },
};
