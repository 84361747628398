export default {
  props: {
    gisApiInfo: {},
    trafficLayerData: {
      type: Object,
      default: null,
    },
    selectTrafficLayer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mapObject: null,
      markerData: [],
      clusterData: [],
      searchData: [],
      eventMarkerData: [],
      eventClusterData: [],
      selectBoxData: [],
    };
  },
  methods: {
    setMarkerData(data) {
      this.markerData = data;
    },
    setClusterData(data) {
      this.clusterData = data;
    },
    setSearchData(data) {
      this.searchData = data;
    },
    setEventMarkerData(data) {
      this.eventMarkerData = data;
    },
    setEventClusterData(data) {
      this.eventClusterData = data;
    },
    setSelectBoxData(data) {
      this.selectBoxData = data;
    },
  },
};
